<template>
  <div>
    <div class="cui__utils__heading">
      <strong>Функционал: Рассылки</strong>
    </div>

    <div class="card">
      <div class="card-header card-header-flex">
        <div class="d-flex flex-column justify-content-center mr-auto">
          <h5 class="mb-0">Новая рассылка</h5>
        </div>
      </div>

      <div class="card-body">
        <a-form-model ref="form" :model="form">
          <a-form-model-item label="Тип" required>
            <a-select v-model="form.status_notice_id" placeholder="выберите статус">
              <a-select-option v-for="type in types" :key="type.id" :value="type.id">
                {{ type.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-item label="Отправить всем">
            <div class="d-flex align-items-center">
              <a-switch v-model="form.all" />
            </div>
          </a-form-item>

          <a-form-model-item label="Получатели">
            <a-select
              v-model="form.recipient_id"
              :default-active-first-option="false"
              :show-arrow="false"
              :filter-option="false"
              :not-found-content="null"
              :disabled="form.all"
              allowClear
              show-search
              multiple="true"
              mode="multiple"
              style="width: 100%"
              @popupScroll="infiniteScroll($event)"
              @select="onSelectUser($event)"
              @search="handleSearch"
              placeholder="выберите получателей"
            >
              <a-select-option v-for="user in [...selectedUsers, ...users]" :key="user.id" :value="user.id">
                {{ user.first_name }} {{ user.last_name }} {{ user.email }}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item ref="active" label="Тема(ru)" required>
            <a-input v-model.number="form.ru.title"/>
          </a-form-model-item>
          <a-form-model-item ref="active" label="Тема(ua)" required>
            <a-input v-model.number="form.ua.title"/>
          </a-form-model-item>
          <a-form-model-item ref="active" label="Содержимое(ru)" required>
            <a-input v-model.number="form.ru.name"/>
          </a-form-model-item>
          <a-form-model-item ref="active" label="Содержимое(ua)" required>
            <a-input v-model.number="form.ua.name"/>
          </a-form-model-item>
        </a-form-model>

        <a-button class="d-block ml-auto" type="primary" @click="submit" :disabled="isDisabled || loading">
          Создать
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash'

export default {
  name: 'create',

  data() {
    return {
      form: {
        ru: {
          name: '',
          title: '',
        },
        ua: {
          name: '',
          title: '',
        },
        all: false,
        recipient_id: [],
      },
      types: [],
      users: [],
      search: '',
      pagination: {},
      selectedUsers: [],
      loading: false,
    }
  },

  created() {
    Promise.all([this.getUsers(), this.getTypes()])
  },

  watch: {
    'form.all'() {
      this.form.recipient_id = []
    },

    search() {
      this.users = []
      this.pagination.current_page = 1
      this.getUsers()
    },

    users: {
      deep: true,
      handler() {
        this.users.forEach((user) => {
          this.selectedUsers = this.selectedUsers.filter((selectedUser) => user.id !== selectedUser.id)
        })
      },
    },
  },

  computed: {
    isDisabled() {
      let isDisabled = false
      const form = this.form
      const { ru, ua } = form

      if (!`${form.status}` || !ru.name || !ru.title || !ua.name || !ua.title) isDisabled = true

      if (!form.all && !form.recipient_id.length) isDisabled = true

      return isDisabled
    },
  },

  methods: {
    getUsers: debounce(async function() {
      const params = {
        search: this.search,
        page: this.pagination.current_page ? this.pagination.current_page : 1,
      }

      try {
        const users = (await this.$services.get('admin/users', { params })).data.data.values
        this.users = this.users.concat(users.data)
        this.pagination = users.pagination
      } catch (e) {
        console.log(e)
      }
    }, 200),

    async getTypes() {
      try {
        this.types = (await this.$services.get('admin/noticeStatus')).data.data.values
      } catch (e) {
        console.log(e)
      }
    },

    handleSearch(value) {
      this.search = value
    },

    onSelectUser(userId) {
      if (this.search) {
        this.selectedUsers.push(this.users.find((user) => user.id === userId))
      }
      this.search = ''
    },

    infiniteScroll(e) {
      const target = e.target
      if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
        if (this.pagination.current_page < this.pagination.total_pages) {
          ++this.pagination.current_page
          this.getUsers()
        }
      }
    },

    async submit() {
      try {
        this.loading = true

        await this.$services.post('admin/notice', this.form)
        this.form = {
          ru: {
            name: '',
            title: '',
          },
          ua: {
            name: '',
            title: '',
          },
          all: false,
          recipient_id: [],
        }

        await this.$router.push({ path: '/functional/mailings' })
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>

</style>
